import { MobileApp } from "../../types/MobileApp";

export const AVAILABLE_APPS: MobileApp[] = [
	{
		name: "App Mormaii",
		image: "/images/appMormaiiSmartwatchesLogo.svg",
		appCode: 0,
	},
	{
		name: "App Technos",
		image: "/images/appTechnosConnectLogo.svg",
		appCode: 1,
	},
	{
		name: "App Touch",
		image: "/images/appTouchSmartLogo.svg",
		appCode: 2,
	},
	{
		name: "App Condor",
		image: "/images/appTouchSmartLogo.svg",
		appCode: 3,
	},
];