/* eslint-disable indent */

import { getCurrentUser, getAdminUsers, sendAdminResetPasswordEmail , postAdminUsers ,  deleteAdminUser, updateAdminUser, updateCurrentUser, updatePassword} from "./AdminUser";
import { getMobileUsers, sendResetPasswordEmail, sendConfirmationEmail, getUsersByFilter, deleteUser } from "./MobileUser";
import { createNewMobileApp, updateMobileApp, deleteMobileApp } from "./MobileApp";
import { getDeviceList, createNewDevice, updateDevice, deleteDevice } from "./Device";
import { getFaqArticleList, updateFaqTheme, deleteFaqTheme, addFaqTheme, addFaqTopic, deleteFaqTopic, updateFaqTopic } from "./Faq";
import { getNotificationList, sendNotification } from "./Notification";
import { getWatchFacesBySection, updateWatchFaceSection, createWatchFaceSection, deleteWatchFaceSection, createWatchFace, deleteWatchFace, updateWatchFace } from "./WatchFace";
import { getWppNumber, saveSupport, saveWppNumber, sendTestEmail, getSupportEmail } from "./Support";
import { getPrivacy, getConsentTerms, getUseTerms, savePrivacy, saveUseTerms, saveConsentTerms, saveTermsVersion, getTermsVersion } from "./Terms";
import { getFirmwareList, createNewFirmware, deleteFirmware, createNewFirmware3in1, deleteFirmware3in1, updateFirmware, updateFirmware3in1, createNewFirmwareSequential, deleteFirmwareSequential } from "./Firmware";
import { getTutorialList, saveTutorial, updateTutorial, deleteTutorial } from "./Tutorial";
import { getStravaInfos, saveStravaInfos } from "./ApiKeys";
import { saveWorkoutSection, updateWorkoutSection, getWorkouts, updateTrainningVideo, deleteWorkoutSection, deleteWorkoutVideo, saveWorkoutVideo } from "./Training";
import { createNewCustomExercise, updateCustomExercise, getCustomExerciseList, deleteCustomExercise } from "./CustomExercise";
import { getSleepScoreList, updateSleepScore } from "./SleepScore";
import { getShareExercise , updateShareExercise, deleteShareExercise, createNewShareExercise } from "./ShareExercise";
import { getAgpsInfos, saveAgpsInfos, getEpoInfos, saveEpoInfos } from "./Gps";
import { sendOrUpdateFileToS3 } from "./AWS";
import axios, { AxiosInstance } from "axios";
import { Auth } from "..";
import { getFaqFeedbackTopicComments, getGroupedFaqFeedback } from "./FaqFeedback";

const mormaiiSmartwatchesApiV4 = axios.create({
	baseURL: process.env.REACT_APP_MORMAII_SMARTWATCHES_BASE_URL + "/api/v4",
	headers: { "Content-Type": "application/json" },
	timeout: 10000,
	timeoutErrorMessage: "Tempo de resposta excedido",
});

const technosConnectApiV4 = axios.create({
	baseURL: process.env.REACT_APP_TECHNOS_CONNECT_BASE_URL + "/api/v4",
	headers: { "Content-Type": "application/json" },
	timeout: 10000,
	timeoutErrorMessage: "Tempo de resposta excedido",
});

const touchSmartApiV4 = axios.create({
	baseURL: process.env.REACT_APP_TOUCH_SMART_BASE_URL + "/api/v4",
	headers: { "Content-Type": "application/json" },
	timeout: 10000,
	timeoutErrorMessage: "Tempo de resposta excedido",
});

const condorSmartApiV4 = axios.create({
	baseURL: process.env.REACT_APP_CONDOR_SMART_BASE_URL + "/api/v4",
	headers: { "Content-Type": "application/json" },
	timeout: 10000,
	timeoutErrorMessage: "Tempo de resposta excedido",
});

export const getApi = (backendCode: number) => {
	switch (backendCode?.toString()) {
		case process.env.REACT_APP_MORMAII_SMARTWATCHES_CODE:
			return mormaiiSmartwatchesApiV4;
		case process.env.REACT_APP_TECHNOS_CONNECT_CODE:
			return technosConnectApiV4;
		case process.env.REACT_APP_CONDOR_SMART_CODE:
			return condorSmartApiV4;
		case process.env.REACT_APP_TOUCH_SMART_CODE:
		default:
			return touchSmartApiV4;
	}
};

export const configApiInterceptors = () => {
	createAxiosResponseInterceptor(mormaiiSmartwatchesApiV4);
	createAxiosResponseInterceptor(technosConnectApiV4);
	createAxiosResponseInterceptor(touchSmartApiV4);
	createAxiosResponseInterceptor(condorSmartApiV4);
};

export const setToken = (token: string) => {
	mormaiiSmartwatchesApiV4.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	technosConnectApiV4.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	touchSmartApiV4.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	condorSmartApiV4.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

function createAxiosResponseInterceptor(axiosObject: AxiosInstance) {
	axiosObject.interceptors.response.use(
		(response) => response,
		(error) => {
			// Reject promise if usual error
			if (error.response.status !== 401) {
				return Promise.reject(error);
			}

			console.log("Erro de autenticaao: " +  JSON.stringify(error));

			Auth.updateToken()
				.then((newToken: string | undefined) => {
					console.log("Token atualizado");
					if(newToken){
						setToken(newToken);
					
						error.response.config.headers["Authorization"] = "Bearer " + newToken;
						// Retry the initial call, but with the updated token in the headers. 
						// Resolves the promise if successful
						console.log("Repetindo requisicao: ", error.response.config.url);
						return axios(error.response.config);
					}
					return Promise.reject(error);
				})
				.catch((error2) => {
					
					Auth.doLogout();
					return Promise.reject(error2);
				});
		}
	);
}

export const AdminUser = {
	getCurrentUser,
	postAdminUsers,
	getAdminUsers,
	deleteAdminUser,
	updateAdminUser,	
	sendAdminResetPasswordEmail,
	updateCurrentUser,
	updatePassword
};

export const MobileUser = {
	getMobileUsers,
	sendResetPasswordEmail,
	sendConfirmationEmail,
	getUsersByFilter,
	deleteUser
};

export const MobileApp = {
	createNewMobileApp,
	updateMobileApp,
	deleteMobileApp
};

export const Device = {
	getDeviceList,
	createNewDevice,
	updateDevice,
	deleteDevice
};

export const Faq = {
	getFaqArticleList,
	updateFaqTheme,
	updateFaqTopic,
	deleteFaqTheme,
	addFaqTheme,
	addFaqTopic,
	deleteFaqTopic
};

export const FaqFeedback = {
	getGroupedFaqFeedback,
	getFaqFeedbackTopicComments,
};

export const Notification = {
	getNotificationList,
	sendNotification
};

export const WatchFace = {
	getWatchFacesBySection,
	updateWatchFaceSection,
	createWatchFaceSection,
	deleteWatchFaceSection,
	createWatchFace,
	deleteWatchFace,
	updateWatchFace
};

export const Support = {
	getWppNumber,
	saveWppNumber,
	getSupportEmail,
	saveSupport,
	sendTestEmail
};

export const Terms = {
	getPrivacy,
	getUseTerms,
	getConsentTerms,
	savePrivacy,
	saveUseTerms,
	saveConsentTerms,
	saveTermsVersion,
	getTermsVersion
};

export const Firmware = {
	getFirmwareList,
	createNewFirmware,
	updateFirmware,
	deleteFirmware,
	createNewFirmware3in1,
	updateFirmware3in1,
	deleteFirmware3in1,
	createNewFirmwareSequential,
	deleteFirmwareSequential
};

export const Tutorial = {
	getTutorialList,
	saveTutorial,
	updateTutorial,
	deleteTutorial
};

export const ApiKeys = {
	getStravaInfos,
	saveStravaInfos
};

export const Training = {
	getWorkouts,
	saveWorkoutSection,
	updateWorkoutSection,
	deleteWorkoutSection,
	saveWorkoutVideo,
	updateTrainningVideo,
	deleteWorkoutVideo
};

export const CustomExercise = {
	getCustomExerciseList,
	createNewCustomExercise,
	updateCustomExercise,
	deleteCustomExercise
};

export const SleepScore = {
	getSleepScoreList,
	updateSleepScore
};

export const ShareExercise = {
	getShareExercise,
	updateShareExercise,
	deleteShareExercise,
	createNewShareExercise
};

export const Gps = {
	getAgpsInfos,
	saveAgpsInfos,
	getEpoInfos,
	saveEpoInfos
};

export const AWS = {
	sendOrUpdateFileToS3,
};
